import { initializeApp } from 'firebase/app'
import { getAuth, EmailAuthProvider, onAuthStateChanged, updateProfile } from 'firebase/auth'
import { getDatabase, ref, set, onValue, child, get, onChildAdded, onChildChanged, onChildRemoved, push, update, remove} from 'firebase/database'
import * as firebaseui from "firebaseui"
import store from "@/store";


const firebaseConfig = {
  apiKey: "AIzaSyDDFIspbjX-y_U_sph6oycH9n0oBLX6Wig",
  authDomain: "moneyman-b5c49.firebaseapp.com",
  databaseURL: "https://moneyman-b5c49-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "moneyman-b5c49",
  storageBucket: "moneyman-b5c49.appspot.com",
  messagingSenderId: "670824119736",
  appId: "1:670824119736:web:7418963369d4657bd3634d",
  measurementId: "G-REEVDMT1YP"
}

const dbname = 'categories';
const autocompleteDb = 'autocomplete';

const initFirebase = async () => {
  let app = await initializeApp(firebaseConfig)
  let auth = await getAuth(app)
  let database = getDatabase(app);
  return { app, auth, database }
}

class FirebaseClass {
  static async create() {
    return initFirebase().then(({app, auth, database}) => {
      return new FirebaseClass(app, auth, database);
    })
  }

  constructor(app, auth, database) {
    this.app = app;
    this.auth = auth;
    this.database = database;
  }

  watchLoginChange () {
    return onAuthStateChanged(this.auth,(user) => {
        if ( !user ) {
          console.warn( `Not loggedIn` )
          this.createLoginUI();
        } else {
          console.warn( `loggedIn` )
        }
    })
  }

  createLoginUI () {
    const ui = new firebaseui.auth.AuthUI(this.auth)
    ui.start("#firebaseui-auth-container", {
      signInSuccessUrl: "/",
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          requireDisplayName: false
        }
      ]
    });
  }

  async createCategory ({id, label, icon, expenses}) {
    return set(ref(this.database, `${dbname}/` + id), {
      id : id,
      label : label,
      icon : icon,
      expenses : expenses
    });
  }
  async removeCategory (category_id) {
    if ( confirm("Remove?") ) {
      await remove(ref(this.database, `${dbname}/` + category_id));
      store.commit('REMOVE_CATEGORY', category_id);
      return true;
    }
  }

  async updateCategory (category_id, key, value) {
    const updates = {};
    updates[`${dbname}/${category_id}/${key}`] = value;
    return update(ref(this.database), updates)
  }

  async loadData () {
    let dbRef = ref(this.database, dbname);
    return new Promise((resolve, reject) => {
      onValue(dbRef, (snapshot) => {
        let data = snapshot.exists() ? snapshot.val() : [];
        store.commit('SET_CATEGORIES', data)
        resolve(data)
      }, {
        onlyOnce: true
      })
    });
  }

  watchCategoryChanges () {
    const categoriesRef = ref(this.database, dbname);
    onChildAdded(categoriesRef, (data) => {
      // store.commit('ADD_CATEGORY', data.val());
    });

    onChildChanged(categoriesRef, (data) => {
      console.warn('onChildChanged')
      store.commit('UPDATE_CATEGORY', {
        category_id : data.key,
        value       : data.val()
      });
    });

    onChildRemoved(categoriesRef, (data) => {
      // store.commit('REMOVE_CATEGORY', {
      //   category_id : data.key
      // });
    });
  }

  async addExpense (data) {
    const categoryRef = ref(this.database, `${dbname}/${data.category_id}/expenses`);
    const newExpenseRef = push(categoryRef);
    let expense = {
      id : newExpenseRef.key,
      amount : data.amount,
      description : data.description,
      category_id : data.category_id,
      timestamp : data.timestamp || new Date().valueOf(),
      creator : data.creator
    }
    await set(newExpenseRef, expense)
  }

  async removeExpense (id, category_id) {
    return this.updateExpense(id, category_id, null)
  }

  async updateExpense (key, category_id, newData) {
    return set(ref(this.database, `${dbname}/${category_id}/expenses/${key}`), newData)
  }

  async updateuser (currentUser, displayName) {
    updateProfile(currentUser, {
      displayName: displayName
    }).then(() => {
      console.warn(`done`);
    }).catch((error) => {
      console.warn(error);
      console.warn(`error`);
    });
  }

}
export default FirebaseClass
