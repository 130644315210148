<template>
  <v-card>
    <ExpenseList :search="true" :expenses="category.expenses" />
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ExpenseList from "@/components/ExpenseList";

export default {
  components : {
    ExpenseList
  },
  data() {
    return {
    }
  },
  computed : mapState({
    category: function(state) {
      const { id } = this.$route.params;
      let category = state.categories[id];
      if (!category.expenses) category.expenses = [];
      let expenses = category.expenses ? Object.values(category.expenses) : [];
      category.expenses = expenses.sort((a,b) => b.timestamp - a.timestamp)
      return category
    }
  }),

  methods : {
    ...mapActions(['setPageTitle']),
    ...mapState(['categories'])
  },

  mounted () {
    this.setPageTitle({
      data : this.category,
      title: this.category.label,
      icon: this.category.icon
    });
  }

};
</script>
