<template>
  <div>
    <v-simple-table
      v-if="expenses"
      fixed-header
      :height="(constrain && expenses.length) > 20 ? '60vh' : 'auto'"
      dense
    >
      <template v-slot:default>
        <colgroup>
          <col width="35%" />
          <col width="55%" />
          <col width="10%" />
        </colgroup>
        <thead v-if="headings">
        <tr>
          <th class="text-left">Amount</th>
          <th class="text-right">Desc/Date</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="expense in expenses"
          :key="expense.id"
        >
          <td :class="wide ? 'pl-0' : ''" class="text-left text-no-wrap">
            <strong v-html="$options.filters.formatAmount(expense.amount, true)"></strong><br/>
            <small>
              <span v-if="expense.creator === 'Alex'">🧑🏻‍🦱</span>
              <span v-if="expense.creator === 'Ilinca'">👩🏼</span>
              {{expense.creator}}</small>
          </td>
          <td class="text-right">
            <div v-if="expense.description">
              <small style="color: blue; font-size: 0.9em">{{ expense.description | shorten(40) }}</small><br/>
            </div>
            <em style="color: gray"><small>{{expense.timestamp | formatDate('ll')}}</small></em>
          </td>
          <td :class="wide ? 'pr-0' : ''">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="editExpense(expense)">
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="blue">mdi-pencil</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item @click="deleteExpense(expense)">
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ExpenseEdit v-if="editable_expense" :expense="editable_expense" @clearEditableExpense="clearEditableExpense" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ExpenseEdit from "@/components/ExpenseEdit";
import {cloneDeep} from "lodash";

export default {
  name: "ExpenseList",
  components : {
    ExpenseEdit
  },
  props : {
    expenses : {
      type: Array,
      default : () => []
    },
    headings : {
      type : Boolean,
      default : true
    },
    wide : {
      type: Boolean,
      default : false
    },
    constrain : {
      type : Boolean,
      default : () => false
    }
  },
  data : () => ({
    editDialog : false,
    editable_expense : null
  }),
  methods : {
    ...mapGetters(['getFirebase', 'getCategories']),
    deleteExpense (expense) {
      const { id, category_id} = expense;
      return this.getFirebase().removeExpense(id, category_id)
    },
    clearEditableExpense () {
      this.editable_expense = null
    },
    editExpense (expense) {
      this.editable_expense = cloneDeep(expense)
    }
  }
}
</script>
