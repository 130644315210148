<template>
  <div>
    <div v-if="this.currentPeriod"
         v-touch="{
          left: () => navPeriod(-1),
          right: () => navPeriod(1)
        }"
    >
      <v-container :style="`height : ${chartHeight}`" class="donut-container">
        <div class="total">
          <strong v-html="$options.filters.formatAmount(totalForPeriod, true)"></strong>
        </div>
        <div class="donut-chart">
          <apexchart ref="piechart" :height="chartHeight" type="donut" :options="piechart.options" :series="piechart.series"></apexchart>
        </div>
      </v-container>
      <v-row
        class="mb-5"
      >
        <v-col cols="auto" v-if="periodType !== 'alltime'">
          <v-btn
            small
            @click="navPeriod(1)" :disabled="!showPrevPeriod"
            fab
            color="primary"
            class="v-btn--example"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-no-wrap text-center">
          <v-btn block depressed text v-html="getCurrentPeriodText(currentPeriod)"></v-btn>
        </v-col>
        <v-col cols="auto" v-if="periodType !== 'alltime'">
          <v-btn
            small
            @click="navPeriod(-1)" :disabled="!showNextPeriod"
            fab
            color="primary"
            class="v-btn--example"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-expansion-panels accordion v-show="showPeriodExpenses">
        <v-expansion-panel
          v-for="(category,i) in categorizeExpenses(currentPeriod.expenses)"
          :key="i"
        >
          <v-expansion-panel-header v-ripple>
            <span @click="$router.push({ path : `/view/${category.id}`})">
              <CatIcon :icon="category.icon" />
              <span>{{ category.label }}</span>
            </span>
            <strong class="flex-grow-0" v-html="$options.filters.formatAmount(category.totalExpenseAmount, true)"></strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-row>
              <v-container class="pa-0">
                <ExpenseList
                  class="py-5"
                  :wide="true"
                  :expenses="category.expenses"
                  :constrain="true"
                  :headings="false"
                />
              </v-container>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from "moment";
import omit from "lodash/omit"
import CatIcon from "@/components/CatIcon";
import ExpenseList from "@/components/ExpenseList";
export default {
  name: "Home",
  components : {
    CatIcon,
    ExpenseList
  },
  props : {
    firePeriodChange : {
      type: Boolean,
      default : false
    }
  },
  watch : {
    firePeriodChange (v , o) {
      if ( v ) this.onPeriodTypeChange();
    }
  },
  data: () => ({
    chartHeight : '250px',
    currentPeriodKey : 'Do MMM YYYY',
    currentPeriodIndex : 0,
    totalForPeriod : 0,
    piechart : {
      options: {
        labels: [],
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '11px'
        }
      },
      series: [],
    },
    showPeriodExpenses : true,
    showNextPeriod : false,
    showPrevPeriod : false
  }),

  computed : mapState({
    loggedIn: function (state) {
      return !!state.user;
    },
    periodType : function(state) {
      return state.periodType;
    },
    categories : function (state) {
      let categories = Object.values(state.categories);
      categories.forEach(cat => {
        let newExp = cat.expenses ? Object.values(cat.expenses) : [];
        cat.expenses = [...newExp];
      })
      return categories;
    },
    getCategoriesForPeriodType : function (state) {
      let periodType = state.periodType;
      let categories = Object.values( state.categories );
      let allExpenses = [];
      categories.forEach(category => {
        if(category.expenses) allExpenses = allExpenses.concat(Object.values(category.expenses))
      });
      let results = {};
      switch ( periodType ) {
        case 'day':
          this.chartHeight = '250px';
          this.currentPeriodKey = 'Do MMM YYYY';
          break;
        case 'month':
          this.chartHeight = '350px';
          this.currentPeriodKey = 'MMM YYYY';
          break;
        case 'year':
          this.chartHeight = '450px';
          this.currentPeriodKey = 'YYYY';
          break;
        case 'alltime':
          this.chartHeight = '450px';
          this.currentPeriodKey = 'N';
          break;
        default:
          break;
      }
      allExpenses.forEach(e => {
        let key = moment(e.timestamp).format(this.currentPeriodKey);
        if (!results[key]) {
          results[key] = {};
          results[key]['expenses'] = [];
        }
        let periodLabel = this.currentPeriodKey === 'N' ? 'All time' : key;
        if (!results[key]['periodLabel']) results[key]['periodLabel'] = periodLabel;
        results[key]['expenses'].push(e);
      })
      results = this.sortByTimestamp(results);
      return results
    },
    currentPeriod : function () {
      let currentPeriodTotals = 0;
      let key = Object.keys(this.getCategoriesForPeriodType)[this.currentPeriodIndex]
      let catsForPeriod = this.getCategoriesForPeriodType[key]
      let categorized = this.categorizeExpenses(catsForPeriod.expenses);
      let labels = [],
        series = [];
      Object.values(categorized).forEach(category => {
        category.expenses.forEach(e => currentPeriodTotals += e.amount);
        labels.push(category.label)
        series.push(category.totalExpenseAmount)
      })
      this.$nextTick(() => {
        this.$refs.piechart.updateOptions({
          series : series,
          labels : labels
        });
      })
      this.totalForPeriod = currentPeriodTotals;
      return catsForPeriod;
    }
  }),

  methods : {
    ...mapActions(['setPageTitle']),
    ...mapGetters(['getFirebase']),
    sortByTimestamp (periods) {
      return Object.keys(periods).sort((a,b) => {
        return moment(b, this.currentPeriodKey).unix() - moment(a, this.currentPeriodKey).unix()
      }).reduce(
        (obj, key) => {
          obj[key] = periods[key];
          return obj;
        },
        {}
      )
    },
    sortByTotalExpensesAmount (categories) {
      let keys = Object.keys(categories)
      let categs = Object.values(categories)
      categs.sort((a,b) => b.totalExpenseAmount - a.totalExpenseAmount)
      let newCategories = {};
      for ( let i in keys) {
        newCategories[keys[i]] = categs[i]
      }
      return newCategories;
    },
    setNextPrevStates() {
      let newIndex = this.currentPeriodIndex;
      let catLength = (Object.values(this.getCategoriesForPeriodType).length - 1);
      this.showNextPeriod = true;
      this.showPrevPeriod = true;
      if ( newIndex === catLength) {
        this.showNextPeriod = true;
        this.showPrevPeriod = false;
      }
      if ( newIndex === 0) {
        this.showNextPeriod = false;
        this.showPrevPeriod = true;
      }
    },
    navPeriod (dir) {
      let newIndex = this.currentPeriodIndex + dir;
      let catLength = (Object.values(this.getCategoriesForPeriodType).length - 1);
      if ( newIndex < 0 || newIndex > catLength ) {
        return;
      }
      this.currentPeriodIndex = newIndex;
      this.setNextPrevStates();
    },
    categorizeExpenses(expenses) {
      let categories = {};
      expenses.forEach( e => {
        let id = e.category_id;
        if (!categories[id]) {
          let _currCat = this.categories.find(c => c.id === id);
          let currCat =  omit(_currCat, ['expenses']);
          categories[id] = currCat;
          categories[id].totalExpenseAmount = 0
          categories[id].expenses = []
        }
        categories[id].totalExpenseAmount += parseFloat(e.amount);
        categories[id].expenses.push(e);
      })
      categories = this.sortByTotalExpensesAmount(categories)
      return categories
    },
    onPeriodTypeChange () {
      this.currentPeriodIndex = 0;
      this.navPeriod(0)
      this.$emit('hideDrawer')
    },
    getCurrentPeriodText (period) {
      let date = moment(period.periodLabel, "Do MMM YYYY");
      let isToday = date.isSame(moment(), 'day');
      let r = period.periodLabel;
      if ( isToday ) {
        r = 'Today'
      }
      return r
    }
  },

  mounted () {
    this.setPageTitle({
      title : "MoneyMan"
    });
    this.onPeriodTypeChange();
  }

};
</script>
<style lang="scss" scoped>
  .donut-container {
      position: relative;
      z-index: auto;
      .total {
          position: absolute;
          left: 50%;
          top: 50%;
          transform : translate(-50%, -50% );
          margin-top: -16px;
      }
      .donut-chart {
          position: relative;
          z-index: 1;
      }
  }
</style>
