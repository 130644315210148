<template>
  <v-card>
    <v-container>

      <v-row v-if="categoryClone">
        <v-col cols="4">
          <v-text-field
            v-model="categoryClone.icon"
            filled
            label="Icon"
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="categoryClone.label"
            filled
            label="Label"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col class="text-left">
          <v-btn color="secondary" @click="$router.go( -1)">Cancel</v-btn>
        </v-col>
        <v-col class="text-right" v-if="isChanged">
          <v-btn color="primary" @click="saveCategoryChanges()">Save</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block outlined color="error" @click="removeCategory()"><v-icon>mdi-trash-can</v-icon> Remove</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CategoryEdit",
  props : {

  },
  watch : {
    categoryClone: {
      handler: function (val) {
        this.isChanged = JSON.stringify(val) !== JSON.stringify(this.category)
      },
      deep: true
    }
  },
  methods : {
    ...mapActions(['getCategoryById']),
    ...mapGetters(['getFirebase']),
    async saveCategoryChanges () {
      await this.getFirebase().updateCategory(this.categoryClone.id, 'icon', this.categoryClone.icon);
      await this.getFirebase().updateCategory(this.categoryClone.id, 'label', this.categoryClone.label);
      this.$router.go(-1);
    },
    async removeCategory () {
      confirm('Not yet');
//      await this.getFirebase().removeCategory(this.categoryClone.id);
    }
  },
  data() {
    return {
      isChanged : false,
      category: null,
      categoryClone : {}
    }
  },
  mounted() {
    const catId = this.$route.params.id;
    this.getCategoryById(catId).then(c => {
      this.category = c;
      this.categoryClone = JSON.parse( JSON.stringify( c ) )
    })
  }
}
</script>
<style lang="scss" scoped>

</style>
