import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import FirebaseClass from './firebase'
import './plugins/moment'
import './filters/filters'

Vue.config.productionTip = false;
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


const startVue = () => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}

const start = async () => {
  const Firebase = await FirebaseClass.create()
  const user = await new Promise((resolve, reject) => {
      const unsubscribe = Firebase.auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
  await store.dispatch('setFirebase', Firebase)
  await store.dispatch('watchCategoryChanges')
  await store.dispatch('loadData')
  Promise.resolve(true);
}
start().then(() => startVue())
