<template>
  <div>
    <v-dialog
    :value="true"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card v-if="expenseReference">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="$emit('clearEditableExpense')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit expense</v-toolbar-title>
      </v-toolbar>
      <v-row no-gutters class="pa-5">
        <v-col cols="12">
          <div class="amountContainer">
            <v-text-field
              v-model.lazy.number.trim="expenseReference.amount"
              type="number"
              outlined prepend-inner-icon="mdi-cash"
              pattern="\d*"
              class="amountInput text-center"
              placeholder="Amount"
              color="green"
            />
          </div>
          <div class="mt-2">
            <v-combobox
              :items="descriptionSuggestions"
              :search-input.sync="newExpenseDescriptionSearch"
              v-model="expenseReference.description"
              placeholder="Description"
              hide-no-data
              hide-details
              outlined prepend-inner-icon="mdi-pencil"
              color="green"
            ></v-combobox>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pa-5">
        <v-dialog
        ref="dialog"
        v-model="dateModal"
        :return-value.sync="editableExpense_date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="getPrettyDate(editableExpense_date)"
            label="Picker in dialog"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="editableExpense_date"
          scrollable
          :max="getMax"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="dateModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="saveExpenseTime(editableExpense_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-col
          v-for="(category, id) in categories"
          :key="id"
          cols="3"
          class="pa-1"
        >
          <v-btn
            :elevation="1"
            height="50"
            width="100%"
            block
            class="pa-1"
            :color="(selectedExpenseCategory === category.id) ? 'primary' : ''"
            @click="selectedExpenseCategory = category.id"
            :ref="`btn_${category.id}`"
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-container class="pa-0">
                <CatIcon :icon="(selectedExpenseCategory === category.id) ? '✅' : category.icon" />
              </v-container>
              <v-container class="pa-0">
                <small style="font-size: 9px">{{  category.label  }}</small>
              </v-container>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="changesMade">
        <v-container>
          <v-btn color="primary" fixed bottom x-large tile block @click="updateExpense(expenseReference)" v-if="expenseReference.amount && expenseReference.category_id">Save</v-btn>
        </v-container>
      </v-row>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CatIcon from "@/components/CatIcon";
import {cloneDeep, isEqual} from "lodash";
import moment from "moment";

export default {
  name: "ExpenseEdit",
  components : {
    CatIcon
  },
  computed : {
    ...mapState({
      categories : function (state) {
        return state.categories;
      }
    }),
    getMax () {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
    }
  },
  watch : {
    newExpenseDescriptionSearch : function(v) {
      if (!v) {
        this.descriptionSuggestions = [];
        return;
      }
      if ( v.length > 1 ) {
        this.descriptionAutocomplete(v, 'get').then(r => {
          this.descriptionSuggestions = r;
        });
      } else {
        this.descriptionSuggestions = [];
      }
    },
    expenseReference : {
      handler (v) {
        this.changesMade = !isEqual(v, this.expense);
      },
      deep: true
    }
  },
  props : {
    expense : {
      type : Object,
      default : () => {}
    }
  },
  data : () => ({
    dateModal : false,
    editExpensePanel: false,
    expenseReference : null,
    editableExpense_date: null,
    changesMade : false,
    newExpenseDescriptionSearch : null,
    selectedExpenseCategory : null,
    descriptionSuggestions : []
  }),
  mounted() {
    this.expenseReference = cloneDeep(this.expense);
    this.editableExpense_date = moment(this.expenseReference.timestamp).format("YYYY-MM-DD");
  },
  methods : {
    ...mapGetters(['getFirebase', 'getCurrentUser']),
    getPrettyDate (date) {
      return moment(date).format('LL');
    },
    saveExpenseTime ( date ) {
      let newTs = moment(date).valueOf() + 21600;
      this.expenseReference.timestamp = newTs;
      this.$refs.dialog.save(date)
    },
    async descriptionAutocomplete ( str, action ) {
      try {
        let res = await fetch('/getStrings', {
          method: "POST",
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify({
            value : str,
            action : action
          })
        }).then(r => r.json())
        return res.data;
      } catch (e) {
        return []
      }
    },

    async updateExpense () {
      let oldCategoryId = this.expense.category_id;
      Promise.all([
        await this.getFirebase().removeExpense( this.expense.id, oldCategoryId),
        await this.getFirebase().addExpense( this.expenseReference )
      ]).then(() => {
        this.$emit('clearEditableExpense');
      })
    }
  }
}
</script>
