<template>
  <span class="catIcon" :style="`font-size: ${size}`">
    {{ icon }}
  </span>
</template>

<script>
export default {
  name: "CategoryIcon",
  props : {
    icon : {
      type: String,
      default : ''
    },
    size : {
      type: String,
      default : '2em'
    }
  }
}
</script>
<style lang="scss" scoped>
  .catIcon {
      display: inline-block;
      vertical-align: middle;
  }
</style>

