import Vue from 'vue'
import moment from "moment";


Vue.filter('formatDate', (value, filterFormat) => {
  if (value) {
    return moment(value).format(filterFormat || "D-MMM-YYYY")
  }
  return ''
})
Vue.filter('shorten', (str, len) => {
  if (str) {
    return (str.length > len) ? str.substr(0, len - 1) + '&hellip;' : str;
  }
  return ''
})
Vue.filter('formatAmount', (amount, html, before) => {
  amount = amount || 0;
  amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if ( html ) return before ? "<small>RON </small>" + amount : amount + "<small> RON</small>";
  return before ? "ᴿᴼᴺ " + amount : amount + " ᴿᴼᴺ";
})
