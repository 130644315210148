<template>
  <v-app class="maxWrap">
    <v-app-bar color="white" fixed>
      <v-btn icon v-if="$route.name !== 'home'">
        <v-icon @click.stop="$router.push({ name : 'home' })">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <CatIcon :icon="title.icon" v-if="title.icon" />
        <strong v-html="title.title"></strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click.stop="editCategory(title.data.id)" icon v-if="$route.name === 'view'">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn @click.stop="menu = !menu" icon v-if="$route.name === 'home'">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer disable-route-watcher fixed bottom temporary touchless v-model="drawer" v-if="$route.name === 'home'">
      <v-list nav dense>
        <v-list-item-group
          v-model="selectedPeriodType"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="Action_setPeriodType('day')">
            <v-list-item-title><v-icon>mdi-white-balance-sunny</v-icon> Day</v-list-item-title>
          </v-list-item>
          <v-list-item @click="Action_setPeriodType('month')">
            <v-list-item-title><v-icon>mdi-calendar-month</v-icon> Month</v-list-item-title>
          </v-list-item>
          <v-list-item @click="Action_setPeriodType('year')">
            <v-list-item-title><v-icon>mdi-calendar-blank</v-icon> Year</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="Action_setPeriodType('alltime')">
            <v-list-item-title><v-icon>mdi-calendar-text</v-icon> All time</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer disable-route-watcher fixed right temporary touchless width="85%" v-model="menu" v-if="$route.name === 'home'">

      <v-container class="text-right mb-2">
        <v-btn text @click="menu = !menu" color="error">Cancel</v-btn>
      </v-container>
      <v-row justify="center">
        <v-col
          v-for="(category, id) in categories"
          :key="id"
          cols="4"
          class="pa-1"
        >
          <v-btn
            :elevation="1"
            height="55"
            block
            class="pa-1"
            @click="menu = false; $router.push({path : `/view/${category.id}`})"
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-container class="pa-0">
                <CatIcon :icon="category.icon" />
              </v-container>
              <v-container class="pa-0">
                <small style="font-size: 9px">{{  category.label  }}</small>
              </v-container>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="pt-16 pb-16">
        <router-view @hideDrawer="hideDrawer" @showDrawer="showDrawer" />
      </v-container>
      <v-btn color="black"
             v-if="$route.name === 'home'"
             @click="drawer = !drawer"
             fab
             fixed
             right
             small
             top
             class="mt-12"
             style="margin-right: -8px"
      >
        <v-icon color="white">
          mdi-calendar-filter-outline
        </v-icon>
      </v-btn>
    </v-main>
    <ExpenseAdd />
  </v-app>
</template>

<script>
import "firebaseui/dist/firebaseui.css"
import {mapActions, mapGetters, mapState} from "vuex";
import CatIcon from "@/components/CatIcon";
import data from "../mergerNode/merged.json";
import ExpenseAdd from "@/components/ExpenseAdd"

export default {
  name: "App",
  components : {
    CatIcon,
    ExpenseAdd
  },
  data: () => ({
    drawer : false,
    menu : false,
    selectedPeriodType : 0
  }),
  computed : mapState({
    title : function (state) {
      return state.pageTitle;
    },
    periodType : function ( state) {
      return state.periodType;
    },
    categories : function (state) {
      return state.categories;
    }
  }),
  methods : {
    ...mapActions(['setPeriodType']),
    ...mapGetters(['getFirebase']),
    editCategory (id) {
      this.$router.push({ path : `/category-edit/${id}`})
    },
    hideDrawer () {
      this.drawer = false;
    },
    showDrawer () {
      this.drawer = true;
    },
    Action_setPeriodType (v) {
      this.setPeriodType(v)
      this.$nextTick(() => {
        this.drawer = false;
      })
    }
  },
  created() {
    // data.forEach( (category) => {
    //   this.getFirebase().createCategory(category)
    // })
    this.setPeriodType('day')
  }
};
</script>
<style>
  .maxWrap {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
  }
</style>
