import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


const store = new Vuex.Store({
  state: () => ({
    categories : [],
    user: null,
    firebase: null,
    periodType : 'day',
    pageTitle : {
      title : 'MoneyMan'
    },
    routerCount : 0
  }),

  mutations: {
    INCREMENT_ROUTER_COUNT (state) {
      state.routerCount += 1;
    },
    SET_CATEGORIES (state, categories) {
      state.categories = categories;
    },
    SET_DISPLAY_PERIOD_TYPE (state, periodType) {
      state.periodType = periodType;
    },
    SET_FIREBASE(state, firebase) {
      state.firebase = firebase
    },
    SET_USER(state, user) {
      state.user = user
    },
    ADD_CATEGORY (state, category) {
      state.categories.push(category)
    },
    SET_PAGE_TITLE( state, _data ) {
      state.pageTitle = _data;
    },
    REMOVE_CATEGORY(state, category_id) {
      let all = state.categories;
      delete all[category_id]
      state.categories = all
      // state.categories = all
    },
    UPDATE_CATEGORY(state, {category_id, value}) {
      let all = state.categories;
      all[category_id] = value;
      // state.categories = all
    }
  },

  getters : {
    getFirebase: (state) => state.firebase,
    getCategories: (state) => state.categories,
    getCurrentUser: (state) => state.user,
    getPageTitle : (state) => state.pageTitle,
    getRouterCount : (state) => state.routerCount
  },

  actions : {
    async setFirebase ({commit, getters}, Firebase) {
      Firebase.watchLoginChange()
      commit('SET_FIREBASE', Firebase)
      commit('SET_USER', Firebase.auth.currentUser)
    },
    async loadData ({getters}) {
      return getters.getFirebase.loadData()
    },
    async watchCategoryChanges ({getters}) {
      return getters.getFirebase.watchCategoryChanges()
    },
    setPageTitle({commit}, _data) {
      commit('SET_PAGE_TITLE', _data);
    },
    setPeriodType({commit}, type) {
      commit('SET_DISPLAY_PERIOD_TYPE', type)
    },
    getCategoryById({getters}, id) {
      let cats = getters.getCategories;
      return cats[id];
    }
  }
})

export default store;
