<template>
  <v-dialog
    v-model="newExpensePanel"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red"
             v-bind="attrs"
             v-on="on"
             fab
             fixed
             left
             large
             bottom
      >
        <v-icon color="white">
          mdi-hand-coin
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="newExpensePanel = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add new expense</v-toolbar-title>
      </v-toolbar>
      <v-row no-gutters class="pa-5">
        <v-col cols="12">
          <div class="amountContainer">
            <v-text-field
              v-model.lazy.number.trim="newExpenseAmount"
              type="number"
              outlined prepend-inner-icon="mdi-cash"
              pattern="\d*"
              class="amountInput text-center"
              placeholder="Amount"
              color="green"
            />
          </div>
          <div class="mt-2">
            <v-combobox
              :items="descriptionSuggestions"
              :search-input.sync="newExpenseDescriptionSearch"
              v-model="newExpenseDescription"
              placeholder="Description"
              hide-no-data
              hide-details
              outlined prepend-inner-icon="mdi-pencil"
              color="green"
            ></v-combobox>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          v-for="(category, id) in categories"
          :key="id"
          cols="3"
          class="pa-1"
        >
          <v-btn
            :elevation="1"
            height="50"
            width="100%"
            block
            class="pa-1"
            :color="(selectedExpenseCategory === category.id) ? 'primary' : ''"
            @click="selectedExpenseCategory = category.id"
            :ref="`btn_${category.id}`"
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-container class="pa-0">
                <CatIcon :icon="(selectedExpenseCategory === category.id) ? '✅' : category.icon" />
              </v-container>
              <v-container class="pa-0">
                <small style="font-size: 9px">{{  category.label  }}</small>
              </v-container>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
      <v-row style="max-width: unset">
        <v-container style="max-width: unset">
          <v-btn color="primary" fixed bottom x-large tile block @click="addExpenseToCategory()" v-if="newExpenseAmount && selectedExpenseCategory">Save</v-btn>
        </v-container>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CatIcon from "@/components/CatIcon";

export default {
  name: "ExpenseAdd",
  components : {
    CatIcon
  },
  computed : {
    ...mapState({
      categories : function (state) {
        return state.categories;
      }
    })
  },
  watch : {
    newExpenseDescriptionSearch : function(v) {
      if (!v) {
        this.descriptionSuggestions = [];
        return;
      }
      if ( v.length > 1 ) {
        try {
          this.descriptionAutocomplete(v, 'get').then(r => {
            this.descriptionSuggestions = r;
          });
        } catch (e) {
          this.descriptionSuggestions = []
        }
      } else {
        this.descriptionSuggestions = [];
      }
    }
  },
  data : () => ({
    newExpensePanel: false,
    newExpenseAmount : null,
    newExpenseDescription : null,
    newExpenseDescriptionSearch : null,
    addSuccess : false,
    selectedExpenseCategory : null,
    descriptionSuggestions : []
  }),
  methods : {
    ...mapGetters(['getFirebase', 'getCurrentUser']),
    async descriptionAutocomplete ( str, action ) {
      try {
        let res = await fetch('/getStrings', {
          method: "POST",
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify({
            value : str,
            action : action
          })
        }).then(r => r.json())
        return res.data;
      } catch (e) {
        return [];
      }
    },
    async addExpenseToCategory () {
      if (!this.newExpenseAmount) return;
      if (!this.selectedExpenseCategory) return;
      await this.getFirebase().addExpense({
        amount: this.newExpenseAmount,
        description: this.newExpenseDescription || '',
        category_id: this.selectedExpenseCategory,
        creator: this.getCurrentUser().displayName
      })
      if ( this.newExpenseDescription ) {
        try {
          await this.descriptionAutocomplete(this.newExpenseDescription, 'put')
        } catch (e) {
          console.error(e);
        }
      }
      this.newExpenseAmount = null;
      this.newExpenseDescription = null;
      this.selectedExpenseCategory = null;
      this.addSuccess = true;
      setTimeout(() => {
        this.addSuccess = false;
      }, 1000)
      setTimeout(() => {
        this.newExpensePanel = false;
      }, 300)
    }
  }
}
</script>
