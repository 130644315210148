import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import View from "@/views/View";
import CategoryEdit from "@/views/CategoryEdit";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/view/:id",
    name: "view",
    component: View
  },
  {
    path: "/category-edit/:id",
    name: "category-edit",
    component: CategoryEdit
  }
  // {
  //   path: "/expense-edit/:id",
  //   name: "expense-edit",
  //   component: CategoryEdit
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  store.commit('INCREMENT_ROUTER_COUNT');
  next();
});
export default router;
